import { Box } from '@mui/material';
import {
  FabMenuProvider,
  LoadingPlaceholder,
  getClassesFromObject,
  useDisclosure,
  useResponsive,
} from '@tyro/core';
import { MailSettingsProvider } from '@tyro/mail';
import {
  ScrollRestoration,
  useLocation,
  useNavigation,
} from 'react-router-dom';
import { HEADER as HEADERCONFIG, NAV } from './nav/config';

import { useEffect, useState } from 'react';
import { useNavigationConfig } from '../../hooks/use-navigation-config';
import { Header } from './header';
import NavMini from './nav/NavMini';
import NavVertical from './nav/NavVertical';
import { AppShellConfigProvider, useAppShellConfig } from './provider';

interface ShellProps {
  children?: React.ReactNode;
}

const msTillShowLoading = 500;
const msToShowLoaderFor = 1000;

function InnerShell({ children }: ShellProps) {
  const isDesktop = useResponsive('up', 'lg');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navConfig = useNavigationConfig();
  const { isNavExpanded, onNavExpand, onNavCollapse } = useAppShellConfig();
  const [loadingState, setLoadingState] = useState({
    loading: false,
    previousPath: window.location.pathname,
    lastChanged: new Date(),
  });
  const location = useLocation();
  const { state } = useNavigation();

  useEffect(() => {
    let timeoutId = null;
    if (state === 'loading') {
      timeoutId = setTimeout(
        () =>
          setLoadingState(({ previousPath }) => ({
            loading: true,
            previousPath,
            lastChanged: new Date(),
          })),
        msTillShowLoading,
      );
    } else if (loadingState.loading === true) {
      const msSinceShow =
        loadingState.lastChanged.getMilliseconds() -
        new Date().getMilliseconds();
      if (msSinceShow > msToShowLoaderFor) {
        setLoadingState({
          loading: false,
          previousPath: window.location.pathname,
          lastChanged: new Date(),
        });
      } else {
        timeoutId = setTimeout(
          () =>
            setLoadingState({
              loading: false,
              previousPath: window.location.pathname,
              lastChanged: new Date(),
            }),
          msToShowLoaderFor - msSinceShow,
        );
      }
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [state, setLoadingState]);

  const isSameAsPreviousFeature =
    location?.pathname.split('/').slice(0, 2).join('/') ===
    loadingState.previousPath.split('/').slice(0, 2).join('/');

  return (
    <>
      <Header isNavExpanded={isNavExpanded} onOpenNav={onOpen} />

      <Box
        sx={{
          display: 'flex',
        }}
      >
        {isDesktop && !isNavExpanded ? (
          <NavMini onExpand={onNavExpand} navConfig={navConfig} />
        ) : (
          <NavVertical
            navConfig={navConfig}
            openNav={isOpen}
            onCollapse={onNavCollapse}
            onCloseNav={onClose}
          />
        )}

        <Box
          component="main"
          className={getClassesFromObject({
            loading: loadingState.loading,
            'same-feature': loadingState.loading && isSameAsPreviousFeature,
          })}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            backgroundColor: 'slate.100',
            minHeight: '100vh',
            position: 'relative',
            pt: `${HEADERCONFIG.H_MOBILE + 8}px`,
            ...(isDesktop && {
              pt: `${HEADERCONFIG.H_DASHBOARD_DESKTOP + 8}px`,
              width: `calc(100% - ${NAV.W_DASHBOARD}px)`,
              ...(!isNavExpanded && {
                width: `calc(100% - ${NAV.W_DASHBOARD_MINI}px)`,
              }),
            }),
            ...(!isDesktop && {
              width: '100%',
            }),
            '.global-loading-spinner, .tab-page-loading-spinner': {
              display: 'none',
            },
            '&.loading': {
              '&.same-feature:has(.tab-page-container)': {
                '& .tab-page-container>*:not(.tab-page-loading-spinner, .MuiTabs-root)':
                  {
                    opacity: 0,
                  },
                '.tab-page-loading-spinner': {
                  display: 'flex',
                },
              },
              '&.same-feature:not(:has(.tab-page-container)), &:not(.same-feature)':
                {
                  '&>*:not(.global-loading-spinner)': {
                    opacity: 0,
                  },
                  '.global-loading-spinner': {
                    display: 'flex',
                  },
                },
            },
          }}
        >
          {children}
          <LoadingPlaceholder
            className="global-loading-spinner"
            sx={{
              height: '100vh',
            }}
          />
          <ScrollRestoration />
        </Box>
      </Box>
    </>
  );
}

export function Shell({ children }: ShellProps) {
  return (
    <AppShellConfigProvider>
      <MailSettingsProvider>
        <FabMenuProvider>
          <InnerShell>{children}</InnerShell>
        </FabMenuProvider>
      </MailSettingsProvider>
    </AppShellConfigProvider>
  );
}
