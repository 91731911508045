import { Card, Stack, useMediaQuery } from '@mui/material';
import type { Editor } from '@tiptap/react';
import { TemplatingContextType, queryClient } from '@tyro/api';
import { useAppShellConfig } from '@tyro/app-shell';
import { TextEditor, useFormValidator, useToast } from '@tyro/core';
import { useTranslation } from '@tyro/i18n';
import type { StudentSelectOption } from '@tyro/people';
import { type Dispatch, type SetStateAction, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { getResolveTemplate } from '../../api/resolve-template';
import { useSaveTemplate } from '../../api/save-templates';
import { useTemplates } from '../../api/templates';
import { ConfirmChangesModal } from '../modals/confirm-changes-modal';
import {
  type PrintTemplateData,
  SelectRecipientsModal,
} from '../modals/select-recipients-modal';
import { TemplateEditorActionButtons } from './template-editor-actions-buttons';
import type { TemplateInformation } from './template-editor-container';
import { TemplateEditorFormFields } from './template-editor-form-fields';

export type TemplateFormState = {
  id?: number;
  name: string;
  template: string;
  context: TemplatingContextType;
};

type EditorInputFieldProps = {
  contextValue: string;
  setContextValue: Dispatch<SetStateAction<string>>;
  editor: Editor | null;
  templateInformation: TemplateInformation;
  templateId: number | undefined;
};

export default function TemplateEditorInputField({
  contextValue,
  setContextValue,
  editor,
  templateId,
}: EditorInputFieldProps) {
  const { t } = useTranslation(['templates']);
  const navigate = useNavigate();
  const [isRecipientsModalOpen, setIsRecipientsModalOpen] = useState(false);
  const [hasUserMadeChanges, setHasUserMadeChanges] = useState(false);

  const initialEditorContent = editor?.getHTML();
  const { isNavExpanded } = useAppShellConfig();
  const { toast } = useToast();
  // True if under 1200px
  const maxCardWidth = useMediaQuery('(max-width: 1200px)');

  const { resolver, rules } = useFormValidator<TemplateFormState>();

  const { data: existingTemplate } = useTemplates({
    ids: [templateId ?? 0],
  });
  const template = existingTemplate?.[0];
  const {
    mutateAsync: saveTemplate,
    isSuccess: hasTemplateBeenSaved,
    isPending: isSavingTemplate,
  } = useSaveTemplate();

  const { control, handleSubmit, reset } = useForm<TemplateFormState>({
    resolver: resolver({
      name: rules.required(),
    }),
    defaultValues: {
      name: template?.name,
    },
  });

  const onSubmit = handleSubmit(async ({ name }: TemplateFormState) => {
    if (!editor) return;

    let htmlContent = editor.getHTML();

    htmlContent = `<div class="template-wrapper">${htmlContent}</div>`;
    await saveTemplate(
      { id: templateId || null, template: htmlContent, name },
      {
        onSuccess: async () => {
          navigate('/templates/list');
        },
      },
    );
  });

  const previewTemplate = async (data: PrintTemplateData) => {
    if (!editor) return;

    let htmlContent = editor.getHTML();
    htmlContent = `<div class="template-wrapper">${htmlContent}</div>`;

    const partyIds = data?.students?.map(
      (student: StudentSelectOption) => student?.partyId,
    );

    const resolvedTemplateResponse = await getResolveTemplate({
      context: TemplatingContextType.Student,
      template: htmlContent,
      partyIds: partyIds ?? [0],
    });

    if (resolvedTemplateResponse.templating_resolveTemplate?.temporaryUrl) {
      window.open(
        resolvedTemplateResponse.templating_resolveTemplate?.temporaryUrl,
        '_blank',
        'noreferrer',
      );
    }
    setIsRecipientsModalOpen(false);
  };

  useEffect(() => {
    const checkChanges = () => {
      setHasUserMadeChanges(editor?.getHTML() !== initialEditorContent);
    };

    editor?.on('update', checkChanges);

    return () => {
      editor?.off('update', checkChanges);
    };
  }, [editor, initialEditorContent]);

  useEffect(() => {
    if (template) {
      reset({
        name: template?.name,
      });
    }
  }, [template, reset]);

  if (!editor) {
    return null;
  }

  return (
    <>
      <form onSubmit={onSubmit}>
        <Stack
          display="flex"
          flexDirection={
            maxCardWidth || (!isNavExpanded && maxCardWidth) ? 'column' : 'row'
          }
          sx={{
            width:
              maxCardWidth || (!isNavExpanded && maxCardWidth)
                ? 'max-content'
                : '100%',
            justifyContent: maxCardWidth ? undefined : 'space-between',
          }}
          mt={2}
        >
          <Card
            variant="soft"
            sx={{
              borderRadius: '26px',
              minWidth: '826px',
              maxWidth: '826px',
              padding: 2,
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
            }}
          >
            <TemplateEditorFormFields
              contextValue={contextValue}
              setContextValue={setContextValue}
              control={control}
            />

            <TextEditor editor={editor} />
          </Card>

          <TemplateEditorActionButtons
            maxCardWidth={maxCardWidth}
            template={template}
            setIsRecipientsModalOpen={setIsRecipientsModalOpen}
            isSavingTemplate={isSavingTemplate}
            onSubmit={onSubmit}
          />
        </Stack>
      </form>
      {!hasTemplateBeenSaved && !isSavingTemplate && (
        <ConfirmChangesModal
          isDirty={hasUserMadeChanges}
          hasTemplateBeenSaved={hasTemplateBeenSaved}
        />
      )}
      <SelectRecipientsModal
        isOpen={isRecipientsModalOpen}
        setOpenRecipientsModal={setIsRecipientsModalOpen}
        previewTemplate={previewTemplate}
      />
    </>
  );
}
